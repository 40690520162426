import React, { useState } from "react"
import { Link } from "gatsby"
import AppContext from "./AppContext"


{/* TODO:
https://getbootstrap.com/docs/4.0/components/navbar/
*/}
{/*<nav className="navbar navbar-light bg-light static-top">
  <div className="container">
    <Link className="navbar-brand" to="/">POC</Link>
	<Link className="nav-link js-scroll-trigger" to="/about">About</Link>
	<Link className="nav-link js-scroll-trigger" to="/contact">Contact</Link>

    <Link className="btn btn-primary" to="/">Demo</Link>
  </div>
</nav>*/}

{

}

const Nav = () => (

	<nav className="navbar navbar-expand-lg navbar-dark bg-dark">
		<div className="container">
			<Link className="navbar-brand" to="/">POC</Link> 
			<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
				<span className="navbar-toggler-icon"></span>
			</button>

			<div className="collapse navbar-collapse" id="navbarSupportedContent">
				<ul className="navbar-nav mr-auto">
				<li className="nav-item">
					<Link className="nav-link" to="/about">About</Link> 
				</li>
				<li className="nav-item">
					<Link className="nav-link" to="/services">Services</Link> 
				</li>
				<li className="nav-item">
					<Link className="nav-link" to="/auth">
						<AppContext.Consumer>
						{ value =>
							<span>{value.authMenuText}</span>
						}
						</AppContext.Consumer>
					</Link> 
				</li>
				<li className="nav-item">
					<Link className="nav-link" to="/contact">Contact</Link> 
				</li>
				<li className="nav-item dropdown">
					<Link className="nav-link dropdown-toggle" to="/" onClick={e => e.preventDefault()} id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
					Demo
					</Link> 
					<div className="dropdown-menu" aria-labelledby="navbarDropdown">
					<a className="dropdown-item" href="?at=1">At.js 1</a> 
					<a className="dropdown-item" href="?lib=dev">Dev Property</a> 
					<div className="dropdown-divider"></div>
					<a className="dropdown-item" href="?lib=websdk">Web SDK</a> 
					<a className="dropdown-item" href="?lib=luma">Luma</a> 
					</div>
				</li>
					{/*
				<li className="nav-item">
					<a className="nav-link disabled" href="javascript:;">Disabled</a> 
				</li>
					*/}
				</ul>
				{/*
				<form className="form-inline my-2 my-lg-0">
				<input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search" />
				<button className="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
				</form>
				*/}
			</div>
		</div>
	</nav>

)

export default Nav
