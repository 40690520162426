/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  const getHelmetScripts = () => {
    let scripts = [
      {
        "src": "https://code.jquery.com/jquery-3.3.1.slim.min.js", 
        "type": "text/javascript"
      },
      {
        "src": "https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.0/umd/popper.min.js", 
        "type": "text/javascript"
      },
      {
        "src": "https://stackpath.bootstrapcdn.com/bootstrap/4.1.0/js/bootstrap.min.js", 
        "type": "text/javascript"
      }
    ];
    const launchLibs = [
      // [0] AGS300 Experience Cloud POC 
      '//assets.adobedtm.com/launch-EN81c8e35f076044ea8928ab527ccb03e5-development.min.js',
      // [1] AGS300 Experience Cloud POC - Dev
      '//assets.adobedtm.com/164e49a27fff/5b0d33ca78ce/launch-39eb5d49edd5-development.min.js',
      // [2] EEEA Vadym Ustymenko Launch Property Dev
      '//assets.adobedtm.com/22bf1a13013f/913cc12d8bb6/launch-c6562568a179-development.min.js',
      // [3] EEEA Vadym Ustymenko Luma Data Collection Property Dev
      'https://assets.adobedtm.com/22bf1a13013f/99bd2e99ce8b/launch-2410923a0e88-development.min.js'
    
    ];
    // Launch library
    if (typeof window !== "undefined"){
      let libIndex = 0;
      var urlString = window.location.href;
      var url = new URL(urlString);
      var lib = url.searchParams.get("lib");
      switch(lib){
        case "dev": libIndex = 1;
          break;
        case "websdk": libIndex = 2;
          break;
        case "luma": libIndex = 3;
          break;
        default: libIndex = 0;
      }
      scripts.splice(0, 0, {
        "src": launchLibs[libIndex], 
        "type": "text/javascript"
      });
    }
    //console.log('scripts',scripts)
    return scripts;
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
      script={getHelmetScripts()}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
